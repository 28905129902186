export const SET_ITEMS = 'SET_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const SET_AMOUNT_CART = 'SET_AMOUNT_CART';
export const SET_ITEMS_LOADING = 'SET_ITEMS_LOADING';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_CURR_CATEGORY = 'SET_CURR_CATEGORY';
export const SET_DELIVERY_POINTS = 'SET_DELIVERY_POINTS';
export const SET_DELIVERY_POINTS_LOADING = 'SET_DELIVERY_POINTS_LOADING';
